import _ from 'lodash';
import styled, { keyframes } from 'styled-components';
// import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import MediaQuery from 'react-responsive';
import { KEY_ADS_POSITION } from 'src/components/ads/dynamic-ads';
import CardH from 'src/components/cards/card-h';
import CardV from 'src/components/cards/card-v';
import SectionFullVH from 'src/components/sections/section-fvh';
import { APP_IMG } from 'src/constants';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { isExistAds } from 'src/utils/helper';
const DynamicAds = dynamic(import('src/components/ads/dynamic-ads'));
const SectionWorldCup = dynamic(import('src/components/sections/section-event/section-world-cup/section-world-cup'));

const SectionOne = ({ data = {}, ads, dataSetWorldCup, dataTagWorldCup }) => {
  if (_.isEmpty(data)) return null;
  const router = useRouter();
  const { block1, block2, block3, headerLatestNews, newHighlight, tags } = data;

  let cloneData = [];
  let dataShift = null;
  let dataSlice = [];
  let dataShiftBlock3 = null;
  let dataSliceBlock3 = [];
  if (!_.isEmpty(block2)) {
    cloneData = [...block2.data];
    dataShift = cloneData.shift();
    dataSlice = cloneData.slice(0, 3);
  }
  if (!_.isEmpty(block3)) {
    cloneData = [...block3.data];
    dataShiftBlock3 = cloneData.shift();
    dataSliceBlock3 = cloneData.slice(0, 3);
  }
  // const eventDataLayer = {
  //   key: KEY_EVENT.PAGE_SECTION,
  //   event: KEY_EVENT.CARD_CONTENT,
  //   section: 'section-1'
  // };
  const cloneLatestNews = {
    heading: 'ข่าวล่าสุด',
    link: '/category/latest-news',
    data: _.isArray(headerLatestNews) && [...headerLatestNews.slice(0, 5)]
  };
  return (
    <>
      <SectionWrapper id='section-1'>
        <div className='container'>
          {/* ---------------------------- HIGHLIGHT && LATEST NEWS ---------------------- */}
          {!_.isEmpty(cloneLatestNews.data) && (
            <SectionFullVH
              heading={cloneLatestNews.heading}
              path={cloneLatestNews.link}
              data={cloneLatestNews.data}
              dataFull={newHighlight}
              ads={ads}
              //! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'section-1',
                position: 'ใต้:โฆษณาตำแหน่ง billboard'
              }}
            />
          )}
          {/* --------------------------- ADS : INARTICLE - 1 -------------------------- */}
          {(isExistAds(ads, KEY_ADS_POSITION.INARTICLE_1) || isExistAds(ads, KEY_ADS_POSITION.INARTICLE_2)) && (
            <div>
              {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_1) && (
                <MediaQuery minWidth={769}>
                  <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />
                </MediaQuery>
              )}
              {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_2) && (
                <MediaQuery maxWidth={768}>
                  <DynamicAds position={KEY_ADS_POSITION.INARTICLE_2} data={ads} />
                </MediaQuery>
              )}
            </div>
          )}

          {/* Section World Cup */}
          {!_.isEmpty(dataTagWorldCup) && !_.isEmpty(dataSetWorldCup) && <SectionWorldCup id='section-world-cup' data={dataTagWorldCup} spacial={dataSetWorldCup} />}
        </div>
      </SectionWrapper>

      {isExistAds(ads, KEY_ADS_POSITION.BILLBOARD) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}

      {/* --------------------------------- BLOCK 1 -------------------------------- */}
      <SectionWrapper id='section-2'>
        <div className='container'>
          {!_.isEmpty(block1?.data) && (
            <div className='mt-30 block-1' data-aos='fade-up'>
              <h2 className='heading'>
                <a title={block1?.title} href={block1?.link}>
                  {block1?.title}
                </a>
              </h2>
              <div className='row  item-card'>
                {block1.data.map((item, index) => (
                  <div key={index} className='col-6 col-md-3 card-v'>
                    <CardV
                      data={item}
                      path={item?.link}
                      //! DATALAYER
                      eventDataLayer={{
                        type: DATALAYER_TYPE.TRACK_POSITION,
                        router: router?.pathname,
                        section: 'section-2',
                        position: 'ใต้:section-1',
                        data: {
                          title: item?.title,
                          heading: block1?.title,
                          index: index + 1
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className='animation-arrow'>
                <div className='animation-left'>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p className='read-more'>
                  <a title=' มีอีกเพียบ อยากอ่านจิ้มตรงนี้ ' href={block1?.link}>
                    {' '}
                    มีอีกเพียบ อยากอ่านจิ้มตรงนี้{' '}
                  </a>
                </p>
                <div className='animation-right'>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          )}
        </div>
      </SectionWrapper>
      <SectionWrapper id='section-3'>
        <div className='container'>
          {/* --------------------------- ADS : INARTICLE - 2 -------------------------- */}
          {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_2) && (
            <div>
              <MediaQuery minWidth={769}>
                <DynamicAds position={KEY_ADS_POSITION.INARTICLE_2} data={ads} />
              </MediaQuery>
            </div>
          )}
          {/* <div>
         <MediaQuery minWidth={769}><DynamicAds type={KEY_ADS_TYPE.INARTICLE} section='desktop' adsName='inarticle-1' /></MediaQuery>
         <MediaQuery maxWidth={768}><DynamicAds type={KEY_ADS_TYPE.INARTICLE} section='mobile' adsName='inarticle-1' /></MediaQuery>
         </div> */}

          {/* --------------------------------- BLOCK 2 -------------------------------- */}

          <div className='row'>
            {!_.isEmpty(block2.data) && (
              <div className='mt-30 mb-15 mb-lg-30 col-12 col-md-6 block-2' data-aos='fade-up'>
                <h2 className='heading mt-15'>
                  <a href={block2?.link} title={block2?.title}>
                    {' '}
                    {block2?.title}{' '}
                  </a>
                </h2>
                <div className='mt-30 card-full-v'>
                  <CardV
                    data={dataShift}
                    path={dataShift?.link}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'section-3',
                      position: 'ใต้:section-2',
                      data: {
                        block: '1',
                        title: dataShift?.title,
                        heading: block2?.title
                      }
                    }}
                  />
                </div>
                {dataSlice.map((item, index) => (
                  <div key={index} className='mt-15 '>
                    <CardH
                      data={item}
                      path={item?.link}
                      showBlurb={true}
                      //! DATALAYER
                      eventDataLayer={{
                        type: DATALAYER_TYPE.TRACK_POSITION,
                        router: router?.pathname,
                        section: 'section-3',
                        position: 'ใต้:section-2',
                        data: {
                          block: '1',
                          title: item?.title,
                          heading: block2?.title,
                          index: index + 2
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
            {/* --------------------------------- BLOCK 3 -------------------------------- */}
            {!_.isEmpty(block3?.data) && (
              <div className='mt-30 mb-15 mb-lg-30 col-12 col-md-6' data-aos='fade-up'>
                <h2 className='heading mt-15'>
                  <a title={block3?.title} href={block3?.link}>
                    {' '}
                    {block3?.title}{' '}
                  </a>
                </h2>
                <div className='mt-30 card-full-v'>
                  <CardV
                    data={dataShiftBlock3}
                    path={dataShiftBlock3?.link}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'section-3',
                      position: 'ใต้:section-2',
                      data: {
                        block: '2',
                        title: dataShiftBlock3?.title,
                        heading: block3?.title
                      }
                    }}
                  />
                </div>
                {dataSliceBlock3.map((item, index) => (
                  <div key={index} className='mt-15 '>
                    <CardH
                      data={item}
                      path={item?.link}
                      showBlurb={true}
                      //! DATALAYER
                      eventDataLayer={{
                        type: DATALAYER_TYPE.TRACK_POSITION,
                        router: router?.pathname,
                        section: 'section-3',
                        position: 'ใต้:section-2',
                        data: {
                          block: '2',
                          title: item?.title,
                          heading: block3?.title,
                          index: index + 2
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* --------------------------- ADS : INARTICLE - 2 -------------------------- */}
          {/* <div>
            <MediaQuery minWidth={769}><DynamicAds type={KEY_ADS_TYPE.INARTICLE} section='desktop' adsName='inarticle-2' /></MediaQuery>
            <MediaQuery maxWidth={768}><DynamicAds type={KEY_ADS_TYPE.INARTICLE} section='mobile' adsName='inarticle-2' /></MediaQuery>
          </div> */}

          {/* ----------------------------- WIDGET_TAG ---------------------------- */}

          {!_.isEmpty(tags) && (
            <div className='row custom-tag' data-aos='fade-in'>
              <div className='col-12'>
                <div className='tags-list'>
                  <h2> แท็กยอดนิยม </h2>
                  <ul>
                    {tags.map((item, index) => (
                      <li key={index}>
                        <a
                          href={`/tags/${item?.name}`}
                          title={item?.name}
                          //! DATALAYER
                          onClick={() =>
                            sendDataLayerGlobal({
                              type: DATALAYER_TYPE.TRACK_POSITION,
                              router: router?.pathname,
                              section: 'section-3',
                              position: 'ใต้:section-2',
                              data: {
                                heading: 'แท็กยอดนิยม',
                                title: item.name,
                                index: index + 1
                              }
                            })
                          }
                        >
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <img src={`${APP_IMG}/images/Icon-152.png`} alt='tag-img' title='tag-img' className='tag-img' width='100%' height='100%' />
                </div>
              </div>
            </div>
          )}
        </div>
      </SectionWrapper>
    </>
  );
};

const rotate = keyframes`
  0% {
    transform: rotate( 0deg ) scale( 1 );
  }
  10% {
    transform: rotate( 10deg ) scale( 1 );
  }
  50% {
    transform: rotate( 20deg ) scale( 1.2 );
  }
`;

const SectionWrapper = styled.section`
  position: relative;
  display: block;
  padding: 0px;
  .block-1 {
    .heading {
      margin-bottom: 15px;
    }
    /* .byline-card-v span {
        font-size: 11px;
        white-space: nowrap;
        width: 100px;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
      } */
  }
  .card-v {
    padding-top: 15px;
  }
  .card-h {
    padding-left: 3px;
  }
  .card-full-v .card-v-content h3 {
    font-size: 20px;
  }
  .card-v-content h3,
  .card-h h3 {
    font-size: 16px;
  }
  .block-style-1 {
    padding-left: 20px;
  }
  .card-v-content-title {
    @media (min-width: 1024px) {
      min-height: 60px !important;
    }
    @media (min-width: 768px) {
      min-height: 30px;
    }
  }
  .byline-card-v span {
    color: #00ff57;
  }

  /* .cardh-noneborder {
    .card-h{
      box-shadow: unset !important;
      border: unset !important;
      padding-left: 3px;
      .resolution-image{
        border-radius: 2px;
      }
      h3 {
        font-size: 16px;
        padding: 0px
      }
    }
    
    .resolution-image {
      position: relative !important;
    }
    .border {
      padding-right: 15px ;
      box-shadow: unset !important;
      border: unset !important;
    }
  } */
  @media (max-width: 768px) {
    .block-style-1 {
      padding-left: 10px;
    }
    .card-h {
      margin-top: 15px;
    }
    .card-v {
      padding: 5px;
      margin-top: 6px;
    }
    .card-full-v {
      .card-v-content-meta {
        display: flex;
      }
    }
  }

  // TAG หน้าแรก********************************
  .custom-tag {
    @media (max-width: 1230px) {
      position: relative !important;
      overflow: hidden;
    }
  }
  .tags-list {
    position: relative;
    background-color: #f5f5f5;
    margin: 30px -5px 5px -5px;
    padding: 10px;
    border-radius: 2px;
    border-left: 10px solid #00ff57;

    @media (max-width: 768px) {
      margin: 30px 0 5px 0;
    }

    h2 {
      width: fit-content;
      font-size: 22px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 0 0 5px;
      margin: 0;
    }

    ul {
      margin-top: 8px;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding-left: 0;

      li {
        margin: 5px;
        border: 1px solid rgba(1, 11, 43, 0.95);
        padding: 3px 10px;
        border-radius: 2px;
        background: transparent;
        font-size: 14px;
        a {
          color: rgba(1, 11, 43, 0.95);
        }

        &:hover {
          background-color: #010b2b;
          border: 1px solid #00ff57;
          a {
            color: #00ff57;
          }
        }
      }
    }
    .tag-img {
      position: absolute;
      right: 15px;
      top: 0;
      /* width: auto; */
      animation: ${rotate} 2s linear infinite;
      max-width: 60px;
      height: auto;
      @media (max-width: 480px) {
        right: 13px;
        top: 6px;
        max-width: 35px;
      }
    }
  }
  //*****************************************************************************************************************************
`;

export default SectionOne;
